import React from "react"
export default function Dialogodisplay(params) {
    var unAnswered = 'gap-3 grid pb-1 bg-purple-400 rounded-lg py-1 px-1'
    var sinResp = 'col-span-2 text-gray-700 border-violet-500 border-x-4 border-y-4 py-1 rounded-lg border-separate bg-gray-200'
    if (params.respondido === 0) {
        unAnswered = 'gap-3 grid pb-1 bg-amber-400 rounded-lg py-1 px-1'
        sinResp = 'col-span-2 text-gray-700 border-violet-500 border-x-4 border-y-4 py-1 rounded-lg border-separate bg-amber-100'
    }
    return (
        <div className='pt-0'>
            <ol type="1" className={unAnswered}>
                <li>
                    <div id='container-dialogo' className='font-mono  gap-1' >
                        <div id='box-remitente' 
                            className={ sinResp}>
                            <div id='fecha' className='px-1 flex gap-2'>
                                <div className='px-1 w-fit text-white bg-sky-500  rounded-lg'>
                                    {"Mensaje de " + params.remitente}:
                                </div>
                                <div>
                                    {new Date(params.fecha).toLocaleDateString()} {new Date(params.fecha).toLocaleTimeString()}
                                </div>
                            </div>
                            <div className='px-1'>
                                {params.pregunta}
                            </div>
                            <div className="lg:flex">
                                {params.nomfoto !== undefined ?
                                    <div id='imagen-adjunta' className='px-1 flex-none  md:flex-auto lg:w-1/2'>
                                        <img className='mx-auto rounded-lg top-0 lg:w-80  md:w-80 max-w-full '
                                            src={'https://escuela.caminantes.cl/images/dialogs/' + params.nomfoto}
                                            alt={params.codigo} />
                                    </div>
                                    :
                                    <></>
                                }
                                <div id='documento'
                                    className='flex-auto px-2 text-md text-blue-800 pb-2 pt-1'>
                                    {params.nomdoc !== null ?
                                        <div className="flex pt-1 pb-1">
                                            <a rel='noreferrer' className='py-1 bg-violet-500 text-white w-fit font-semibold rounded-lg h-8 px-1'
                                                target='_blank' download={true}
                                                href={'https://escuela.caminantes.cl/docs/' + params.nomdoc}>
                                                {'Documento adjunto'}
                                            </a>
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                        <div id='destinatario-respuesta'
                            className='bg-gray-200 px-1 rounded-lg border-4 pt-1 border-violet-500'>
                            <div className='text-md  border-violet-500 rounded-lg border-separate text-purple-800 min-h-32'>
                                <div className='px-2 w-fit text-white bg-sky-500 rounded-lg'>
                                    {"Respuesta de " + params.destinatario}:
                                </div>
                                <span className='px-1'>
                                    {params.respuesta}
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    )
}
