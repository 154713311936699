import useSessionStorageState from 'use-session-storage-state'
import Sectionhead from './Sectionhead';

export default function Horario() {
  const logged = useSessionStorageState('logged');
  if (logged) {
    return (
      <div className='pt-1'>
        <Sectionhead titulo={'Horario Anual'}/>
        <div className=' flex-auto items-center bg-violet-300'>
          <img src='./images/horario.jpg' alt="Horario" className=' border-red-800 lg:px-20 pt-5 pb-5 lg:py-2'></img>
        </div>
      </div>
    )
  }
  else {
    <div className=" bg-violet-500">

    </div>
  }
}
