function borrarFoto(img, deldir) {
    const formData = new FormData();
    formData.append('foto', img);
    formData.append('imgdir', deldir)
    const delFoto = async () => {
        const response = await fetch('https://php.caminantes.cl/delFoto.php/', {
            method: 'POST',
            body: formData
        })
        console.log(response)
    }
    delFoto()
}

export {borrarFoto}