
import Navbar from './elements/Navbar';

export function startOfWeek() {
  var date = new Date();
  // var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  var diff = date.getDate() - date.getDay() +  (date.getDay() === 6 ? 2 : 1);
  var start = new Date(date.setDate(diff));
  return start.toLocaleDateString();
}
export default function App() {
  return (
    <div className=' bg-sky-500 min-h-screen px-0'>
      <Navbar />
    </div>
  )
}

