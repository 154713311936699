import React from 'react'

export default function Sectionhead(params) {
  return (
    <div className=''>
      <div className='bg-violet-700 px-3 rounded-lg 
        h-8 shrink text-center py-1 text-white text-md font-semibold'>
        <h1 id="titulo">
          {params.titulo}
        </h1>
      </div>
    </div>
  )
}
