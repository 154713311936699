import React from 'react'

export default function Boton(params) {
    if (params.textBoton !== '') {
        return (
            <button onClick={params.funcion}
                type="button" 
                className="bg-violet-700 text-md border-0 text-white py-auto px-2 rounded-lg h-8"
                 >
                {params.textBoton}
            </button>
        )
    }
    else{
        return(<></>)
    }
}