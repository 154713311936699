import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Mensajeinput from './Mensajeinput';
import Dialogodisplay from './Dialogodisplay';
import useSessionStorageState from 'use-session-storage-state'
import Resizer from "react-image-file-resizer";

const DialogosDeEscuela = () => {
    const [ok, setOk] = useState(false);
    const [mostrar, setMostrar] = useState(0);
    const logged = useSessionStorageState('logged')[0];
    const usuario = useSessionStorageState('usuario')[0];
    const admin = usuario.TIPUSUARIO === 2 ? true : false
    const [fotosdialogs, setFotosDialogs] = useState([]);
    const [dialogos, setDialogos] = useState([]);
    const [selected, setSelected] = useState("");
    const [codmsg, setCodmsg] = useState('');
    const [listdestinatarios, setListdestinatarios] = useState([]);
    //funciones
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                    console.log(uri)
                },
                "base64"
            );

        });
    const fetchUsers = async () => {
        try {
            const response = await axios.post("https://familia.caminantes.cl/usuarios", {
                method: "POST",
                mode: "cors",  // Change the mode to CORS
                params: {
                    logged: logged,
                    admin: admin
                }
            });
            let usuarios = [];
            if (admin === false) {
                usuarios = response.data.data.filter((s) => {
                    return (s.TIPUSUARIO !== 1)
                });
            }
            else {
                usuarios = response.data.data
            }
            setListdestinatarios(usuarios)
        } catch (error) {
            console.error(error);
        }
    };
    function recibeMensaje(mensaje) {
        if (mensaje.msg !== '') {
            envMensaje(mensaje);
            document.getElementById("familia").selectedIndex = 0;
            setSelected('');
            fetchDialogosUser();
        }
        else {
            alert('Escribe el texto del mensaje')
        }
    }
    const envMensaje = (mensaje) => {
        const postMensaje = async () => {
            if (mensaje.msg !== "") {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/dialogos", {
                        method: "POST",
                        mode: "cors",  // Change the mode to CORS
                        params: {
                            remitente: "'" + usuario.NOMUSUARIO + "'",
                            pregunta: "'" + mensaje.msg + "'",
                            destinatario: "'" + selected + "'"
                        }
                    });
                    handleUploadDoc(mensaje.doc, response.data.data.insertId)
                    handleUploadFoto(response.data.data.insertId, mensaje.file);
                    setOk(!ok);
                } catch (error) {
                    console.error(error);
                }
            }
            admin === true ? fetchDialogosAdmin() : fetchDialogosUser()
        }
        postMensaje();
    }
    const respMensaje = (respuesta) => {
        if (respuesta.msg !== '') {
            const postRespuesta = async () => {
                try {
                    await axios.post("https://familia.caminantes.cl/respuesta", {
                        method: "POST",
                        mode: "cors",  // Change the mode to CORS
                        params: {
                            coddialogo: codmsg,
                            respuesta: respuesta.msg
                        },
                    });
                } catch (error) {
                    console.error(error);
                }
                setOk(!ok);
                admin === true ? fetchDialogosAdmin() : fetchDialogosUser()
            }
            postRespuesta()

        }
    }
    const borraDialogo = async (id, dest) => {
        if (window.confirm('Borrar este diálogo con "' + dest + '" ?')) {
            try {
                await axios.delete("https://familia.caminantes.cl/dialogos/borrar/'" + id + "'", {
                    method: "POST",
                    mode: "cors",  // Change the mode to CORS
                    params: {
                        id: id,
                    },
                });
            } catch (error) {
                console.error(error);
            }
            setOk(!ok);
            fetchDialogosUser()
        }
    };
    const handleUploadDoc = async (doc, coddialogo) => {
        if (doc) {
            let filename = coddialogo.toString();
            const formData = new FormData();
            formData.append('file', doc);
            // admin === true && selected!=='' ? filename = selected + '-De-' + usuario.NOMUSUARIO : filename = usuario.NOMUSUARIO + '-A-' + selected;
            formData.append('text', filename);
            const response = await fetch('https://php.caminantes.cl/uploadDoc.php', {
                method: 'POST',
                body: formData,
            });
            setSelected('');
            setOk(!ok);
            response.status === 200 ?
                alert("Documento enviado OK") :
                alert('El documento no fue enviado, pídele a Pepe que te asesore')
        }
    };
    const handleUploadFoto = async (nomfoto, image) => {
        if (image !== '') {
            let dsImage = (await resizeFile(image));
            const formData = new FormData();
            formData.append('img', dsImage);
            formData.append('nom', nomfoto + ".jpg");
            formData.append('dir', '../escuela/images/dialogs/')
            const response = await fetch('https://php.caminantes.cl/downSizeAll.php', {
                method: 'POST',
                body: formData,
            });
            if (response.status !== 200) { alert('La foto no pudo ser agregada') }
            setOk(!ok)
        }
    };
    const fetchDialogosUser = async () => {
        if (logged === true) {
            try {
                const response = await axios.post('https://familia.caminantes.cl/persona/', {
                    method: "POST",
                    mode: "cors",  // Change the mode to CORS
                    params: {
                        nombre: usuario.NOMUSUARIO,
                        logged: logged
                    }
                }
                );
                let dialogosFetched = response.data.data;
                setDialogos(dialogosFetched.filter((f) => { return f.RESPONDIDO === mostrar }))
            } catch (error) {
                console.error(error);
            }
        }
    };
    const fetchDialogosAdmin = async () => {
        try {
            const response = await axios.post('https://familia.caminantes.cl/dialogosadmin/', {
                method: "POST",
                mode: "cors",  // Change the mode to CORS
                logged: logged
            }
            );
            let dialogosFetched = response.data.data;
            setDialogos(dialogosFetched.filter((f) => { return f.RESPONDIDO === mostrar }));
        } catch (error) {
            console.error(error);
        }
    };
    const fetchFotosDialogs = async () => {
        var formData = new FormData();
        formData.append('logged', logged)
        await axios.post('https://php.caminantes.cl/listaFotosDialogs.php', formData,
            {
                method: "POST",
                mode: "cors",  // Change the mode to CORS
            })
            .then(function (response) {
                setFotosDialogs(response.data);
            });
    }
    useEffect(() => {
        fetchFotosDialogs()
        fetchUsers();
        admin === true ? fetchDialogosAdmin(mostrar) : fetchDialogosUser(mostrar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ok, mostrar]);
    if (logged) {
        return (
            <>
                <div id='titulo' className='pt-1 pb-0 flex-1'>
                    <div id='display-pend-resp' className=' gap-1 flex flex-grow text-center h-fit'>
                        <div className='w-1/2 h-8 py-1 rounded-lg bg-violet-700 font-bold text-amber-400 cursor-pointer'
                            onClick={() => setMostrar(0)}>
                            {'Pendientes'}
                        </div>
                        <div className='w-1/2 h-8 py-1 rounded-lg bg-violet-700 text-white cursor-pointer'
                            onClick={() => setMostrar(1)}>
                            Respondidos
                        </div>
                    </div>

                </div>
                <div id='mensaje' className='pt-1 bg-sky-500 rounded-lg px-0' >

                    <select id="familia" value={selected} name="familia"
                        onChange={(e) => {
                            setSelected(e.target.value);
                        }}
                        className=" bg-violet-700 w-full hover:text-black hover:bg-white
                                        text-white rounded-lg h-8" type="button " >
                        <option value={''} className='text-center '>Selecciona un Destinatario/a</option>
                        {listdestinatarios &&
                            listdestinatarios.map((d, index) => {
                                return <option key={index} value={d.NOMUSUARIO}>{d.NOMUSUARIO}</option>;
                            })}
                    </select>
                    <div id='escribe msg' className='rounded-lg pt-1'>
                        {selected === '' ?
                            <></>
                            :
                            <div className=''>
                                <div className='bg-sky-500 rounded-lg pt-1 p-4 grid-cols-2 border-2 
                                 px-1 py-0 '>
                                    <div className='p-1'>
                                        <Mensajeinput
                                            funcion={recibeMensaje}
                                            borrar={true}
                                            plantilla={'Escribe aquí tu mensaje para ' + selected}
                                            confoto={true}
                                            condoc={true}
                                            cancelar={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div id="documentos" className='pt-1'>
                    </div>
                </div>
                {dialogos ? dialogos.map((resp, index) =>
                    <div key={index} className='pt-1 rounded-lg'>
                        <div className='text-md'>
                            <Dialogodisplay
                                nombre={resp.NOMUSUARIO}
                                codigo={resp.CODDIALOGO}
                                key={index}
                                index={index}
                                fecha={resp.FECDIALOGO}
                                remitente={resp.REMITENTE}
                                destinatario={resp.DESTINATARIO}
                                pregunta={resp.PREGUNTA}
                                respuesta={resp.RESPUESTA}
                                respondido={resp.RESPONDIDO}
                                nomfoto={fotosdialogs.find((f) => { return f === resp.CODDIALOGO + '.jpg' })}
                                nomdoc={null}
                            />
                            <div className='' >
                                {(resp.RESPONDIDO === 0 && resp.REMITENTE === usuario.NOMUSUARIO) ?
                                    <div
                                        className='text-white font-semibold bg-violet-700 cursor-pointer  
                                        h-6 relative  -bottom-1 left-1  px-1 py-0 w-fit rounded-lg'
                                        onClick={() => borraDialogo(resp.CODDIALOGO, resp.DESTINATARIO)}>
                                        {"Borrar este mensaje"}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            {(resp.DESTINATARIO === usuario.NOMUSUARIO & resp.RESPUESTA === '') ?
                                <div className=''>
                                    <div onClick={() => { setCodmsg(resp.CODDIALOGO) }}
                                        className="relative pt-2 bottom-36 h-1 left-3 w-full pr-10 ">
                                        <Mensajeinput
                                            plantilla={'Responder a ' + resp.REMITENTE + ' mensaje ' + resp.CODDIALOGO}
                                            funcion={respMensaje}
                                            borrar={true}
                                        />
                                    </div>
                                </div>
                                :
                                <></>}
                        </div>
                    </div>
                )
                    : ''
                }
            </>
        )
    }
}
export default DialogosDeEscuela;
