"use client"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sectionhead from './Sectionhead';
import Mensajeinput from './Mensajeinput';
import Boton from './Boton';
import useSessionStorageState from 'use-session-storage-state'
import Resizer from "react-image-file-resizer";
import {borrarFoto} from './Funciones'


const Comunicaciones = () => {
    const logged = useSessionStorageState('logged')[0];
    const usuario = useSessionStorageState('usuario')[0];
    const grupo = useSessionStorageState('grupo')[0];
    const admin = useSessionStorageState('admin')[0];
    const nomgrupo = useSessionStorageState('nomgrupo')[0];
    const [data, setData] = useState(null);
    const [ok, setOk] = useState(true)
    const [cantidad, setCantidad] = useState(3)
    const [fotoscomms, setFotosComms] = useState('')

    function envComunicacion(texto, file) {
        const postComunicacion = async () => {
            if (texto !== '') {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/ingcom", {
                        method: "POST",
                        mode: "cors",  // Change the mode to CORS
                        params: {
                            comunicacion: texto + '. Atentamente, ' + usuario.NOMUSUARIO,
                            grupo: grupo
                        },
                    })
                    if (file !== undefined) { handleUploadFotoComm(response.data.data.insertId, file) }
                } catch (error) {
                    console.error(error);
                }
            }
            setOk(!ok)
        };
        postComunicacion()
    }
    function delComunicacion(id, texto) {
        if (window.confirm('Borrar esta comunicación: "' + texto + '" ?' + id)) {
            const delCom = async () => {
                if (id !== null) {
                    try {
                        await axios.delete("https://familia.caminantes.cl/delcom/'" + id + "'", {
                            method: "POST",
                            mode: "cors",  // Change the mode to CORS
                            params: {
                                id: id,
                            },
                        });
                    } catch (error) {
                        console.error(error);
                    }
                    setOk(!ok);
                }
                borrarFoto(id + ".jpg", '../escuela/images/comms/')
            };
            delCom();
        }
    }
    function recibeMensaje(mensaje) {
        envComunicacion(mensaje.msg, mensaje.file)
    }
    const handleUploadFotoComm = async (nomfoto, image) => {
        if (image !== '') {
            let dsImage = (await resizeFile(image));
            const formData = new FormData();
            formData.append('img', dsImage);
            formData.append('nom', nomfoto + ".jpg");
            formData.append('dir', '../escuela/images/comms/')
            const response = await fetch('https://php.caminantes.cl/downSizeAll.php', {
                method: 'POST',
                body: formData,
            });
            if (response.status !== 200) { alert('La foto no pudo ser agregada') }
            fetchFotosComms()
            setOk(!ok)
        }
    };
    const fetchFotosComms = async () => {
        var formData = new FormData();
        formData.append('logged',logged)
        axios.post('https://php.caminantes.cl/listaFotosComms.php',formData,{
            method:'POST',
            mode:'cors',
        })
        .then(function (response) {
            setFotosComms(response.data)
            setOk(!ok)
        });
    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );

        });
    useEffect(() => {
        fetchFotosComms()
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://familia.caminantes.cl/comunica/", {
                    method: "GET",
                    mode: "cors",  // Change the mode to CORS  
                }
                );
                let datos = response.data.data;
                datos = datos.filter((f) => { return (f.GRUPO === grupo || f.GRUPO === 0) })
                setData(datos)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [ok, grupo, cantidad]);
    if (logged === true) {
        return (
            <div className='pt-1'>
                <Sectionhead titulo={"Libreta de Comunicaciones - " + Date().toString().substring(0, 10)} />
                {admin === true ?
                    <div className='pt-1 '>
                        <div id="caja texto comunicación" className='flex-col'>
                            <Mensajeinput
                                plantilla={"Escribe aquí una comunicación para " + nomgrupo}
                                funcion={recibeMensaje}
                                borrar={true}
                                confoto={true}
                                cancelar={true}
                            />
                        </div>
                    </div>
                    : ''
                }
                <div className='pt-1 pb-1'>
                    <Boton textBoton="Ver comunicaciones anteriores"
                        funcion={() =>
                            cantidad === 1 ? setCantidad(8) : setCantidad(3)
                        }
                    />
                </div>
                {data ? data.slice(0, cantidad).map((comm, index) =>
                (
                    <div key={index} className='bg-black flex flex-row basis-0.5  pt-1 pb-1' >
                        <div className='border-yellow-500 bg-white border-4 px-3 pt-1 w-full
                                rounded-lg border-separate'>
                            <div className='font-bold px-1 pt-1 '>
                                {new Date(comm.FECCOMUNICACION).toLocaleDateString()}
                                {comm.GRUPO === 1 ? ' Camino de la Montaña ' :
                                    comm.GRUPO === 0 ?
                                        " Comunidad Escuela " :
                                        " Camino del Bosque "}
                            </div>
                            <div className='px-1'>{comm.TXTCOMUNICACION}</div>
                            <div className='px-1'>
                                {fotoscomms && fotoscomms.find((foto) => foto === (comm.CODCOMUNICACION + ".jpg")) ?
                                    <img src={'https://escuela.caminantes.cl/images/comms/' + comm.CODCOMUNICACION.toString() + '.jpg'}
                                        className='p-1 rounded-lg '
                                        alt={comm.CODCOMUNICACION}>
                                    </img> : <></>}
                            </div>
                            <div className='pt-1 pb-1' hidden={!admin}>
                                <Boton textBoton="Borrar" funcion={() => delComunicacion(comm.CODCOMUNICACION, comm.TXTCOMUNICACION)} />
                            </div>
                        </div>
                    </div>
                )) : ''
                }
            </div>
        )
    }
}
export default Comunicaciones;
