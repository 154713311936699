import { React, useEffect, useState } from "react";
import Comunicaciones from "./Comunicaciones";
import Horario from "./Horario";
import Fotos from "./Fotos";
import Semana from "./Semana";
import Signin from "./Signin";
// import DialogosDeEscuela from "./DialogosDeEscuela";
import Sectionhead from "./Sectionhead";
import Boton from "./Boton";
import axios from "axios";
import Swal from 'sweetalert2'
import useSessionStorageState from 'use-session-storage-state'
import DialogosDeEscuela from "./DialogosDeEscuela";

export default function Navbar() {
    const [pendientes, setNumPendientes] = useState(0)
    const [logged, setLogged] = useSessionStorageState('logged', { defaultValue: false })
    const [usuario, setUsuario] = useSessionStorageState('usuario', { defaultValue: [] })
    const [admin, setAdmin] = useSessionStorageState('admin', { defaultValue: false })
    const [grupo, setGrupo] = useSessionStorageState('grupo', { defaultValue: 0 })
    const [nomgrupo, setNomgrupo] = useSessionStorageState('nomgrupo', { defaultValue: 'Comunidad Escuela' });
    const [pagina, setPagina] = useState('inicio')
    const [ok, setOk] = useState(true)
    const fecha = new Date()
    const hoy = fecha.getDate() + '-' + (fecha.getMonth() + 1) + '-' + fecha.getFullYear();
    const [url, setUrl] = useState('url("escuela.jpg")')
    
    
    function getTxtgrupo(grp) {
        switch (grp) {
            case 0:
                setUrl("url('escuela.jpg')")
                setNomgrupo('Comunidad Escuela')
                break
            case 1:
                setUrl("url('andes.jpg')")
                setNomgrupo('Camino de la Montaña')
                break
            case 2:
                setUrl("url('bosque.jpg')")
                setNomgrupo('Camino del Bosque')
                break
            default:
                setUrl("url('escuela.jpg')")
                setNomgrupo('')

        }
    }
    const fetchPendientes = async () => {
        if (logged === true) {
            try {
                const response=await axios.post('https://familia.caminantes.cl/dialogospend/', {
                    method: "POST",
                    mode: "cors",  // Change the mode to CORS
                    logged:true,
                    nombre:usuario.NOMUSUARIO,
                    admin:admin
                })
                setNumPendientes(response.data.data[0].pend)
            } catch (error) {
                console.error(error);
            }
            setTimeout(fetchPendientes, 1000000);
        }
    };
    const salir = () => {
        localStorage.clear()
        sessionStorage.clear()
        setLogged(false);
        setAdmin(false)
        setUsuario([]);
        setUrl("url('escuela.jpg')");
        setNomgrupo('');
        setGrupo('')
        setOk(!ok);
        setPagina('login')
        setNumPendientes(0)
    }
    useEffect(() => {
        if (logged === true && pendientes > 0 && grupo !== null) {
            Swal.fire({
                title: "Dialogos",
                text: usuario.NOMUSUARIO + ', tienes dialogos por revisar: ' + pendientes,
                icon: "warning",
                toast: true
            }).then(okay => {
                if (okay) {
                    setPagina('dialogos');
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendientes])
    useEffect(() => {
        setPagina('inicio');
        setUrl("url('escuela.jpg')");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged])
    useEffect(() => {
        getTxtgrupo(grupo);
        fetchPendientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grupo])
    return (
        <div className="px-1 w-full bg-sky-500 h-window">
            <div className=" px-1 bg-blend-hard-light">
                <div className="pt-1">
                    <Sectionhead titulo={'Caminar Conectados ' + hoy} />
                </div>
                {logged === true ?
                    < div id="estudiante" className="pt-1">
                        <Sectionhead titulo={usuario.NOMESTUDIANTE} />
                    </div>
                    : ''
                }
                <div id="imagen-fondo" className=" pt-1 pb-1">
                
                    <div className="rounded-lg pb-0 lg:h-60" style={{
                        backgroundImage: url,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <img id="logo" alt="logo" src="./logo192.png"
                            className='mx-auto my-auto lg:pt-6 lg:w-60 w-48'>
                        </img>
                    </div>
                </div>
                {logged === true ?
                    <div id="debajo-imagen" className="pt-1 w-full">
                        <div id="nombres-user-grupo" className="text-center flex gap-1 pt-1 w-full ">
                            <div id="nombreusuario"
                                className=" h-8 flex-grow bg-violet-700 text-white 
                                rounded-lg w-1/2 text-center pt-1 py-1 font-bold">
                                {usuario.NOMUSUARIO}
                            </div>
                            <div id="nomgrupo"
                                className="pb-0 flex-grow text-nowrap text-center w-1/2 cursor-pointer"
                                onClick={() => 
                                    {
                                    if (
                                        admin === true
                                        && pagina !== 'dialogos'
                                        && pagina !== 'horario'
                                        && pagina !== 'fotos'
                                    ) 
                                    {
                                        grupo === 1 ?
                                            setGrupo(2) :
                                            grupo === 2 ?
                                                setGrupo(0) :
                                                setGrupo(1)
                                    }
                                    if (admin === false && logged===true) {
                                        pagina === 'semana' ? setPagina('comunicaciones') : setPagina('semana')
                                    }
                                }}>
                                <Sectionhead titulo={
                                    admin === true ?
                                        pagina === 'dialogos'
                                            || pagina === 'horario'
                                            || pagina === 'fotos' ? 'Comunidad Escuela' :
                                            nomgrupo
                                        :
                                        pagina === 'semana' ? 'Ver Comunicaciones' : 'Ver Ruta Semanal'
                                } />
                            </div>
                        </div>
                        <div id="botones-menu" className="text-center flex pt-1 gap-1 w-full" >
                            <div className="h-8 flex-grow bg-violet-700 text-white 
                                rounded-lg w-1/4 text-center pt-1 py-1 px-1 
                                font-semibold overflow-hidden cursor-pointer text-nowrap">
                                <div onClick={() => {
                                    setPagina("inicio")
                                }}>Comunicaciones</div>
                            </div>
                            <div className="h-8 flex-grow bg-violet-700 text-white 
                                rounded-lg w-1/4 text-center pt-1 py-1 px-1 font-semibold cursor-pointer">
                                <div onClick={() => {
                                    setPagina("semana")
                                }}>Calendario</div>
                            </div>
                            <div id="countmensajes"  className="h-8 flex-grow bg-violet-700 text-white 
                                rounded-lg w-1/4 text-center pt-1 px-1 py-1 font-semibold cursor-pointer"
                                onClick={() => { setPagina('dialogos') }}>
                                {'Diálogos'}
                            </div>
                            <div className="h-8 flex-grow bg-violet-700 text-white lg:text-sm
                                rounded-lg w-1/4 text-center pt-1 px-1 py-4 font-semibold cursor-pointer "
                                onClick={() => { setPagina('fotos') }} >{'Álbum'}
                            </div>
                            <div className="h-8 flex-grow bg-violet-700 text-white 
                                rounded-lg w-1/4 text-center pt-1 py-1 font-semibold cursor-pointer"
                                onClick={() => salir()} >Salir
                            </div>
                        </div>
                    </div>
                    :
                    <div id="ingresar" className="flex gap-3 w-full" >
                        {!logged ?
                            <div className="flex">
                                <Boton textBoton="Comenzar"
                                    funcion={() => {
                                        document.getElementById('msgError').innerText="";
                                        pagina==='inicio'?
                                            setPagina('login')
                                            : pagina==='login'?
                                            setPagina(''):setPagina('login')
                                        setUrl("url('escuela.jpg')");
                                        setNomgrupo('');
                                        setGrupo('')
                                    }
                                    } />
                                <div id="msgError" className=" pl-2 text-center py-1 text-white rounded-lg">
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                }
                <div id="nuevaclave" className="rounded-lg"></div>
            </div>
            <div id="pagina" className="bg-sky-500 px-1 ">
                {(pagina === '' || pagina === 'inicio') ?
                    <>
                        <Comunicaciones />
                    </>
                    : ''}
                {pagina === 'comunicaciones' ? <Comunicaciones /> : ''}
                {pagina === 'semana' ? <Semana  /> : ''}
                {pagina === 'dialogos' ? <DialogosDeEscuela/> : ''}
                {pagina === 'fotos' ? <Fotos /> : ''}
                {pagina === 'horario' ? <Horario /> : ''}
                {pagina === 'login' ? <Signin /> : ''}
                {pagina === '' ? <></> : <></>}
                {pagina === 'cambia' ? '' : ''}
            </div>
        </div>
    )
}

// funcion={recibeConteo}
