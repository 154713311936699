import React, { useEffect, useState, useRef } from 'react'
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import Sectionhead from './Sectionhead';
import Boton from './Boton';
import ImageGallery from "react-image-gallery";
import useSessionStorageState from 'use-session-storage-state'
import Resizer from "react-image-file-resizer";

export default function Fotos() {
  const [refresh, setRefresh] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagelink, setImageLink] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [gallery, setGallery] = useState([])
  const fileInputRef = useRef(null);
  const logged = useSessionStorageState('logged')[0];
  const nombre = useSessionStorageState('usuario')[0].NOMUSUARIO
  const admin = useSessionStorageState('admin')[0]

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtension = ['.jpg', '.png', '.jpeg', '.jfif'];
      const selectedFileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtension.includes('.' + selectedFileExtension)) {
        setSelectedFile(file);
        setValidationError(null);
      }
      else {
        setSelectedFile(null);
        setValidationError('Por favor seleccione archivos con extensión .jpg, .jpeg, .jfif o .png ');
        fileInputRef.current.value = '';
      }
    }
  };
  const handleUpload = async () => {
    if (selectedFile) {
      await resizeFile(selectedFile)
        .then((response) => {
          const formData = new FormData();
          formData.append('img', response);
          formData.append('nom', nombre);
          formData.append('dir', '../escuela/images/fotos/');
          const sube = fetch('https://php.caminantes.cl/downSizeAll.php', {
            method: 'POST',
            body: formData,
          }).then((resp) => {
            resp.status === 200 ? alert("Foto Agregada") : alert('La foto no pudo ser agregada')
            setRefresh(!refresh)
            setImageLink(sube);
            fileInputRef.current.value = '';
            setSelectedFile(false);
          });
        })
    }
    else {
      alert('Selecciona una foto antes de enviar');
    }
  };
  function borrarFoto(img) {
    const formData = new FormData();
    formData.append("imgdir", '../escuela/images/fotos/');
    formData.append("foto", decodeURI(img));
    const delFoto = async () => {
      await axios.post('https://php.caminantes.cl/delFoto.php', formData,
        {
          method: 'POST',
          mode: 'cors'
        }
      )
    }
    delFoto()
    setRefresh(!refresh);
  }
  const getFotos = async () => {
    var formData = new FormData();
    formData.append("logged", logged)
    await axios.post('https://php.caminantes.cl/listaFotos.php', formData,
      {
        method: 'POST',
        mode: 'cors',

      }).then(function (response) {
        if (response.data !== '') {
          var fotocas = response.data.filter((nom) => { return nom !== '.' && nom !== '..' })
          setGallery(fotocas.map((mapa) => {
            return (
              {
                original: "https://escuela.caminantes.cl/images/fotos/" + mapa,
                thumbnail: "https://escuela.caminantes.cl/images/fotos/" + mapa,
                thumbnailWidth: '150',
                thumbnailHeight: '150',
                thumbnailClass: '',
                originalClass: 'lg:h-fit h-96 w-full my-auto align-middle mx-auto object-fill',
              })
          }));
        }
      });
  }
  useEffect(() => {
    getFotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  if (logged) {
    return (
      <div className='pt-1'>
        <Sectionhead titulo={'Imágenes de nuestro caminar'} />
        <div hidden={false} className="pt-1 align-middle object-contain">
          <ImageGallery
            showIndex={true}
            renderFullscreenButton={true}
            slideOnThumbnailOver={true}
            onThumbnailClick={(event, index) => {
              let f = event.target.currentSrc.indexOf("fotos") + 6
              let foto = (event.target.currentSrc.slice(f, event.target.src.lenght))
              // console.log(decodeURI(foto))
              if (admin === true || decodeURI(foto.substr(0, foto.indexOf('-'))) === nombre) {
                if (window.confirm(nombre + " confirma que quieres borrar esta Foto que puso " 
                + decodeURI(foto.substr(0, foto.indexOf('-'))) + " en el álbum")) { borrarFoto(foto) }
              }
            }
            }
            autoPlay={true}
            showPlayButton={false}
            useBrowserFullscreen={false}
            items={gallery}
            showFullscreenButton={false}
            thumbnailPosition={'right'}
            allowOutsideClick={false}
          />
        </div>
        <div className='bg-purple-400 rounded-lg'>
          <div className="grid pt-4 pb-4  justify-items-center justify-self-auto">
            <input className="justify-self-auto bg-violet-500 "
              type="file" ref={fileInputRef} onChange={handleFileChange} />
          </div>
          <div className="grid justify-items-center pb-2">
            <Boton textBoton={'Agregar imagen'} funcion={() => handleUpload()} />
          </div>
          <div className="row">
            <div className="col col-3">
              {validationError && (
                <p >{validationError}</p>
              )}
              {imagelink && (
                <div hidden={true}>
                  <p><b>Foto agregada al álbum </b></p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
